import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from "react-bootstrap/Container"
import Header from "../components/header"
import Row from "react-bootstrap/Row"

const Contact = () => (
  <Layout>
    <SEO title="Contact" />
    <Header title="Contact" />
    <Container
      fluid="md"
      style={{
        padding: `0 2rem`,
      }}
    >
      <Row style={{ justifyContent: "center" }}>
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSef_3XXcUB3DCeRkoq1Sf7C6MuSOdB5H9pBlMdhAM_OktX6HQ/viewform?embedded=true"
          width="640"
          height="800"
          frameborder="0"
          marginheight="0"
          marginwidth="0"
        >
          Loading…
        </iframe>
      </Row>
    </Container>
  </Layout>
)

export default Contact
